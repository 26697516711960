import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import './banner.css';

const Banner = () => (
    <div className="banner-container">
    <Jumbotron className="banner-image" style={{ marginBottom: 0, borderRadius: 0 }}>
          <h1> Passion for your property </h1>
          <p> Discover how a new living space can make a real difference to any home</p>
    </Jumbotron>
  </div>
);

export default Banner;

import React from 'react';
import Image from 'react-bootstrap/Image'

import './qualifications.css'

const Qualifications = () => (
    <div className="qualifications-container">
        <h1 className="qualifications-heading"> Certifications </h1>
        <hr />
        <Image className="qualifications-image" src="/images/qualifications/certass-certified-installer.svg" fluid />
        <Image className="qualifications-image" src="/images/qualifications/qa-logo.svg" fluid />
    </div>
)

export default Qualifications;

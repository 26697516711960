const sendEmail = async (data) => {

    // full out the form elements
    const { name, email, phone, postcode, products, message } = data;
    
    // Add the headers to the HTML request
    var myHeaders = new Headers();
    myHeaders.append("X-API-KEY", "oNduUOLhZk2aUDO45rMKk7RzudE6YlfK1AYjJAdY");
    myHeaders.append("Content-Type", "application/json");

    // Build the request body with form values
    var raw = JSON.stringify({
        "to":"neilexton@sky.com",
        "from":"extonhomeimprovements@gmail.com",
        "fullname": name,
        "telephone": phone,
        "email": email,
        "postcode": postcode,
        "services": products,
        "enquiry": message
    });

    // Build the request object
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    // Send form data to SES
    await fetch("https://aaqq7vu2fk.execute-api.eu-west-1.amazonaws.com/dev/send-email-ehi", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));

};

export default sendEmail;

import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';

import './products.css';

const Products = () => (
    <div className="products-container" id="products-heading">
        <h1 className="products-heading"> Products </h1>
        <hr />
        <CardDeck className="product-card-deck">
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/windows-home-page.svg" />
                <Card.Body>
                <Card.Title>Windows</Card.Title>
                <Card.Text>
                    Explore a variety of windows, including uPVC double glazing, aluminium and hardwood
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/doors-home-page.svg" />
                <Card.Body>
                <Card.Title>Doors</Card.Title>
                <Card.Text>
                    Discover our range of contemporary, modern and traditional doors, including side screens and patio doors
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/conservatories-home-page.svg" />
                <Card.Body>
                <Card.Title>Conservatories</Card.Title>
                <Card.Text>
                    Enquire about our extensive range of conservatories and orangeries available in aluminium, hardwood or uPVC
                </Card.Text>
                </Card.Body>
            </Card>
        </CardDeck>

        <CardDeck className="product-card-deck" style={{ paddingTop: '2rem' }}>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/skypod-home-page.svg" />
                <Card.Body>
                <Card.Title>Roof Lanterns</Card.Title>
                <Card.Text>
                    See how a roof lantern can transform your home into a brand new space, filling it with natural light
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/roofline-home-page.svg" />
                <Card.Body>
                <Card.Title>Roof Line</Card.Title>
                <Card.Text>
                    See how our soffit, fascia and guttering in AluTech or uPVC can bring a fresh new look to your property
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/bathroom.svg" />
                <Card.Body>
                <Card.Title> Bathrooms </Card.Title>
                <Card.Text>
                    Transform your bathroom with a selection of brand new modern or traditional suites
                </Card.Text>
                </Card.Body>
            </Card>
        </CardDeck>

        <CardDeck className="product-card-deck" style={{ paddingTop: '2rem' }}>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/kitchen-home-page.svg" />
                <Card.Body>
                <Card.Title> Kitchens </Card.Title>
                <Card.Text>
                    We offer complete kitchen refurbishments, from complete tear outs to an entirely fresh build
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/bifold-doors.svg" />
                <Card.Body>
                <Card.Title>Bifold Doors</Card.Title>
                <Card.Text>
                    Explore our wide range of aluminium bifold doors, including Smart Origin and Corniche  
                </Card.Text>
                </Card.Body>
            </Card>
            <Card className="product-card">
                <Card.Img variant="top" src="/images/products-svg/garden-room.svg" />
                <Card.Body>
                <Card.Title>Garden Rooms</Card.Title>
                <Card.Text>
                    Create a stylish outdoor space with our contemporary garden rooms built to your specification
                </Card.Text>
                </Card.Body>
            </Card>
        </CardDeck>
    </div>
);

export default Products;
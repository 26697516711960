import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import sendEmail from '../../../api/sendEmail';
import LoadingSpinner from '../../LoadingSpinner';

import './contact.css'

const Contact = () => {

    // State
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);
    const [sending, setSending] = useState(false);
    const [values, setValues] = useState ({
        name: '',
        email: '',
        phone: '',
        postcode: '',
        message: ''
    });

    // Handlers
    const handleNameInputChange = (event) => {
        setValues({...values, name: event.target.value})
    };

    const handleEmailInputChange = (event) => {
        setValues({...values, email: event.target.value})
    };

    const handlePhoneInputChange = (event) => {
        setValues({...values, phone: event.target.value})
    };

    const handlePostcodeInputChange = (event) => {
        setValues({...values, postcode: event.target.value})
    };

    const handleMessageInputChange = (event) => {
        setValues({...values, message: event.target.value})
    };

    // Form submission
    const submitForm = async (event) => {
        event.preventDefault();
        setSending(true);

        const payload = {
            name: values.name,
            phone: values.phone,
            email: values.email,
            postcode: values.postcode,
            products: 'TBA',
            message: values.message
        };
        
        await sendEmail(payload)
            .then(() => {
                setSending(false);
                setSubmitted(true)
            })
            .catch(error => {
                console.log(error);
                setError(true); 
            });
    }

    return (
        <div className="contact-container" id="contact">
            <h1 className="contact-heading"> Contact Us </h1>
            <hr />
            { sending ? <LoadingSpinner/> :
                <Form
                    className="form-container"
                    id="contact-form"
                    onSubmit={submitForm}
                >
                    <Form.Group as={Row}>
                        <Form.Label style={{ position: 'static' }} className="form-label" column sm={2}>
                            Name
                        </Form.Label>
                        <Col style={{ position: 'static' }} sm={10}>
                            <Form.Control
                                id="name"
                                placeholder="Name"
                                value={values.name}
                                onChange={handleNameInputChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label style={{ position: 'static' }} className="form-label" column sm={2}>
                            Email
                        </Form.Label>
                        <Col style={{ position: 'static' }} sm={10}>
                            <Form.Control
                                id="email"
                                type="email"
                                placeholder="Email"
                                value={values.email}
                                onChange={handleEmailInputChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label style={{ position: 'static' }} className="form-label" column sm={2}>
                            Tel. Number
                        </Form.Label>
                        <Col style={{ position: 'static' }} sm={10}>
                            <Form.Control
                                id="phone"
                                type="tel"
                                placeholder="Contact Tel. Number"
                                value={values.phone}
                                onChange={handlePhoneInputChange}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label style={{ position: 'static' }} className="form-label" column sm={2}>
                            Postcode
                        </Form.Label>
                        <Col style={{ position: 'static' }} sm={10}>
                            <Form.Control
                                id="postcode"
                                placeholder="Postcode"
                                value={values.postcode}
                                onChange={handlePostcodeInputChange}
                            />
                        </Col>
                    </Form.Group>

                    {/* <fieldset>
                        <Form.Group as={Row}>
                        <Form.Label style={{ position: 'static' }} as="legend" column sm={2}>
                            Product Interest
                        </Form.Label>
                        <Col style={{ position: 'static' }} sm={10}>
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Windows"
                            name="formHorizontalRadios"
                            id="windowsCheckbox"
                            />
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Doors"
                            name="formHorizontalRadios"
                            id="doorsCheckbox"
                            />
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Conservatories"
                            name="formHorizontalRadios"
                            id="conservatoriesCheckbox"
                            />
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Sky Pods"
                            name="formHorizontalRadios"
                            id="skypodsCheckbox"
                            />
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Roof Line"
                            name="formHorizontalRadios"
                            id="rooflineCheckbox"
                            />
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Kitchens"
                            name="formHorizontalRadios"
                            id="kitchensCheckbox"
                            />
                            <Form.Check
                            style={{ position: 'static' }}
                            type="checkbox"
                            label="Bathrooms"
                            name="formHorizontalRadios"
                            id="bathroomsCheckbox"
                            />
                        </Col>
                        </Form.Group>
                    </fieldset> */}
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label style={{ position: 'static' }}>Your message</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows="3"
                            value={values.message}
                            onChange={handleMessageInputChange}
                        />
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col style={{ position: 'static' }} className="button-container">
                            <Button className="submit-button" type="submit">Submit</Button>
                        </Col>
                    </Form.Group>
                </Form>
            }
            { submitted ? <div className='success-message'>Success! Your message has been sent</div> : null }
            { error ? <div className='error-message'>There was a problem with your request. Please try again later</div> : null }
        </div>
    )
}

export default Contact;

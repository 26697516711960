import React from 'react';
import Products from './Products';
import Banner from '../Banner';
import Qualifications from './Qualifications';
import Contact from './Contact';

import './home.css'

const Home = () => (
    <div className="home-container">
        <Banner />
        <Products />
        <Qualifications />
        <Contact />
    </div>
)

export default Home;

import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';

const Navigation = () => (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Link to="/">
            <Navbar.Brand>
                <img
                    src="/images/white-logo-no-text.svg"
                    width="160"
                    height="62"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
            </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Link href="/">
                    Home
                </Nav.Link>
                {/* <Nav.Link href="/testimonials">
                    Testimonials
                </Nav.Link> */}
            {/* <NavDropdown title="Products and Services" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/windows">
                    Windows
                </NavDropdown.Item>
                <NavDropdown.Item href="/doors">
                    Doors
                </NavDropdown.Item>
                <NavDropdown.Item href="/conservatories">
                    Conservatories
                </NavDropdown.Item>
                <NavDropdown.Item href="/skypods">
                    Sky Pods
                </NavDropdown.Item>
                <NavDropdown.Item href="/roofline">
                    Roof Line
                </NavDropdown.Item>
                <NavDropdown.Item href="/kitchens">
                    Kitchens
                </NavDropdown.Item>
                <NavDropdown.Item href="/bathrooms">
                    Bathrooms
                </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="#contact">
                Contact
            </Nav.Link>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
);

export default Navigation;

import React from 'react';
import Image from 'react-bootstrap/Image'

import './footer.css'

const getCurerntYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
}

const Footer = () => (
    <div className="footer-container">
        <p> © <b>Exton Home Improvements </b> {getCurerntYear()}. All rights reserved. </p>
        <a href="https://www.facebook.com/ExtonHomeImprovements" target="_blank" rel="noopener noreferrer">
            <Image className="facebook-logo" src="/images/social/facebook.png" fluid />
        </a>
    </div>
);

export default Footer;

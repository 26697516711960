import React from 'react';
import { Route } from 'react-router-dom';
import { Home, Products } from './';

import Navigation from './Navigation';
import Footer from './Footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navigation />
      <body>
        <Route exact path="/" component={Home} />
        <Route path="/products" component={Products} />
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
